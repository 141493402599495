<template>
  <div>
    <div class="container-fluid">
      <div class="row page-titles">
        <div class="col-md-5 align-self-center">
          <h4 class="text-themecolor">Pre-Registration Dashboard</h4>
        </div>
        <div class="col-md-7 align-self-center text-right">
          <div class="d-flex justify-content-end align-items-center">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="javascript:void(0)">Home</a>
              </li>
              <li class="breadcrumb-item active">Pre-Registration Dashboard</li>
            </ol>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <form class="bg-white shadow-md rounded px-8 pt-6 pb-8" @submit.prevent="doFilter">
                <div class="row">
                  <div class="col-md-2 d-flex align-items-center mb-3 mb-sm-0">
                    <h5 class="mb-0">Filter</h5>
                  </div>
                  <div class="col-md-10 d-flex justify-content-end align-items-center">
                    <div class="row justify-content-end">
                      <div class="col-12 col-sm-12 col-lg-auto px-0">
                        <v-date-picker
                          v-model="range"
                          mode="dateTime"
                          :masks="masks"
                          is-range
                          is24hr
                        >
                          <template v-slot="{ inputValue, inputEvents, isDragging }">
                            <div class="row flex-column flex-sm-row justify-content-end align-items-start">
                              <div class="col-12 col-sm-auto position-relative flex-grow-0">
                                <svg
                                  style="width: 16px;"
                                  class="position-absolute h-100 mx-2"
                                  fill="none"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                                  ></path>
                                </svg>
                                <input
                                  class="flex-grow pr-2 py-1 bg-light border rounded w-100"
                                  style="padding-left:2rem;"
                                  :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                                  :value="inputValue.start"
                                  v-on="inputEvents.start"
                                  placeholder="DD/MM/YYYY"
                                />
                              </div>
                              <div class="col-auto d-inline-block mx-2 mt-2">
                                <span class="flex-shrink-0">
                                  <i class="d-none d-sm-inline fas fa-arrow-right"></i>
                                </span>
                              </div>
                              <div class="col-12 col-sm-auto position-relative flex-grow-0">
                                <svg
                                  style="width: 16px;"
                                  class="position-absolute h-100 mx-2"
                                  fill="none"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                                  ></path>
                                </svg>
                                <input
                                  class="flex-grow pr-2 py-1 bg-light border rounded w-100"
                                  style="padding-left:2rem;"
                                  :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                                  :value="inputValue.end"
                                  v-on="inputEvents.end"
                                  placeholder="DD/MM/YYYY"
                                />
                              </div>
                            </div>
                          </template>
                        </v-date-picker>
                      </div>
                      <div class="col-12 col-sm-2 col-lg-auto mt-2 mt-sm-2 mt-lg-0 ml-sm-2 px-0">
                        <button class="btn btn-block btn-primary">Filter</button>
                      </div>
                      <div class="col-12 col-sm-2 col-lg-auto mt-2 mt-sm-2 mt-lg-0 ml-sm-2 px-0">
                        <button class="btn btn-block btn-outline-success" type="button" @click="doResetFilter">Reset</button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-12">
                  <h5>Total Resume</h5>
                </div>
              </div>
              
              <b-overlay :show="loadingQuota">
                <div class="row mt-4">
                  <div class="col-lg-4">
                    <div class="card border rounded">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-3 col-sm-auto">
                            <div class="stats-icon bg-primary text-white">
                              <i class="fas fa-users"></i>
                            </div>
                          </div>
                          <div class="col-9 col-sm-9">
                            <div class="sc-meta">
                              <div class="scm-count">
                                <span>{{ totalResume }}</span>
                              </div>
                              <span class="scm-label">Total Pre-Registrations</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="card border rounded">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-3 col-sm-auto">
                            <div class="stats-icon bg-primary text-white">
                              <i class="fas fa-users"></i>
                            </div>
                          </div>
                          <div class="col-9 col-sm-9">
                            <div class="sc-meta">
                              <div class="scm-count">
                                <span>{{ totalResumeIndonesian }}</span>
                              </div>
                              <span class="scm-label">Indonesian</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div class="col-lg-4">
                    <div class="card border rounded">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-3 col-sm-auto">
                            <div class="stats-icon bg-primary text-white">
                              <i class="fas fa-users"></i>
                            </div>
                          </div>
                          <div class="col-9 col-sm-9">
                            <div class="sc-meta">
                              <div class="scm-count">
                                <span>{{ totalResumeNonIndonesian }}</span>
                              </div>
                              <span class="scm-label">Non-Indonesian</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-overlay>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-12">
                  <h5>Categories Resume</h5>
                </div>
              </div>
              
              <b-overlay :show="loadingCategories">
                <b-row v-if="!loadingCategories">
                  <div class="col-lg-12">
                    <v-chart class="chart" :option="chartCategories" autoresize />
                  </div>
                </b-row>
                <div v-else style="height:475px;"></div>
              </b-overlay>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-12">
                  <h5>Countries Resume</h5>
                </div>
              </div>
              
              <b-overlay :show="loadingCountries">
                <b-row v-if="!loadingCountries">
                  <b-col lg="12">
                    <v-chart class="chart" :option="chartCountries" autoresize />
                  </b-col>
                </b-row>
                <div v-else style="height:475px;"></div>
              </b-overlay>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import GlobalVue from "@libs/Global";

import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart, BarChart, LineChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";

use([
  CanvasRenderer,
  BarChart,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  LineChart
])

// import("echarts/core").then(({ use }) => {
//   import("echarts/renderers").then(({ CanvasRenderer }) => {
//     import("echarts/charts").then(({ PieChart, BarChart, LineChart }) => {
//       import("echarts/components").then(({ TitleComponent, TooltipComponent, LegendComponent, GridComponent }) => {
//         use([
//           CanvasRenderer,
//           BarChart,
//           PieChart,
//           TitleComponent,
//           TooltipComponent,
//           LegendComponent,
//           GridComponent,
//           LineChart,
//         ])
//       }).catch(error => {
//         console.error('Failed to dynamically import ECharts components:', error)
//       })
//     }).catch(error => {
//       console.error('Failed to dynamically import ECharts charts:', error)
//     })
//   }).catch(error => {
//     console.error('Failed to dynamically import ECharts renderers:', error)
//   })
// }).catch(error => {
//   console.error('Failed to dynamically import ECharts core:', error)
// })

import VChart from "vue-echarts"

import Gen from "@/libs/Gen"

export default {
  extends: GlobalVue,
  components: {
    VChart,
  },
  data() {
    return {
      range: {
        start: null,
        end: null,
      },
      masks: {
        input: 'D MMM YYYY',
      },
      startDate: '',
      endDate: '',

      loadingQuota: false,
      loadingCategories: false,
      loadingCountries: false,

      totalResume: 0,
      totalResumeIndonesian: 0,
      totalResumeNonIndonesian: 0,
      categoriesResume: [0, 0, 0, 0, 0, 0],
      categoriesResumeLabel: ['Maratoonz', '5KM', '5KM Group', '10KM', 'Half Marathon', 'Marathon'],
      countriesResume: [],
      countriesResumeNonIndonesian: [],
    }
  },
  computed: {
    formattedRange() {
      let dateRange = this.range;
      (dateRange || {}).start = (dateRange || {}).start != null ? `${this.$moment((dateRange || {}).start).format('YYYY-MM-DD HH:mm')}:00` : null;
      (dateRange || {}).end = (dateRange || {}).start != null ? `${this.$moment((dateRange || {}).end).format('YYYY-MM-DD HH:mm')}:00` : null;

      return dateRange;
    },
    chartCategories() {
      return {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: this.categoriesResumeLabel,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            interval: 0,
            rotate: 30
          },
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            name: 'Total Pre-Registrations',
            type: 'bar',
            data: this.categoriesResume,
            color: '#009BFF',
            barWidth: '20%',
          },
        ]
      }
    },
    chartCountries() {
      return {
        legend: {
          data: ["Indonesia"],
          orient: "vertical",
          align: "left",
          left: "left",
          selected: {
            'Indonesia': false,
          },
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c} ({d}%)',
        },
        series: [
          {
            name: 'Country',
            type: 'pie',
            radius: '55%',
            center: ['50%', '60%'],
            data: this.countriesResume,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      }
    },
  },
  methods: {
    doFilter() {
      this.loadingQuota = true
      this.loadingCategories = true
      this.loadingCountries = true

      setTimeout(() => {
        this.get_total_resume()
        setTimeout(() => {
          this.get_categories_resume()
          setTimeout(() => {
            this.get_countries_resume()
          }, 2000);
        }, 100);
      }, 100);
    },
    doResetFilter() {
      this.range = {
        start: null,
        end: null,
      }

      this.doFilter()
    },
    get_total_resume(){
      Gen.apiRest(
        "/do/"+this.modulePage,
        {
          data: {
            type: 'totalResume',
            date: this.formattedRange
          },
        },
        'POST'
      ).then(res=>{
        this.loadingOverlay = false
        this.loadingQuota = false
        
        const data = res.data

        this.totalResume = data.totalResume
        this.totalResumeIndonesian = data.totalResumeIndonesian
        this.totalResumeNonIndonesian = data.totalResumeNonIndonesian
      }).catch(()=>{
        this.loadingOverlay = false
        this.loadingQuota = false
      })
    },
    get_categories_resume(){
      Gen.apiRest(
        "/do/"+this.modulePage,
        {
          data: {
            type: 'categoriesResume',
            date: this.formattedRange
          },
        },
        'POST'
      ).then(res=>{
        this.loadingOverlay = false
        this.loadingCategories = false
        
        const data = res.data

        this.categoriesResume = data.categoriesResume
        this.categoriesResumeLabel = data.categoriesResumeLabel
      }).catch(()=>{
        this.loadingOverlay = false
        this.loadingCategories = false
      })
    },
    get_countries_resume(){
      Gen.apiRest(
        "/do/"+this.modulePage,
        {
          data: {
            type: 'countriesResume',
            date: this.formattedRange
          },
        },
        'POST'
      ).then(res=>{
        this.loadingOverlay = false
        this.loadingCountries = false
        
        const data = res.data

        this.countriesResume = data.countriesResume
        this.countriesResumeNonIndonesian = data.countriesResumeNonIndonesian
      }).catch(()=>{
        this.loadingOverlay = false
        this.loadingCountries = false
      })
    },
  },
  created() {
    this.loadingQuota = true
    this.loadingCategories = true
    this.loadingCountries = true
    
    setTimeout(() => {
      this.get_total_resume()
      setTimeout(() => {
        this.get_categories_resume()
        setTimeout(() => {
          this.get_countries_resume()
        }, 1000);
      }, 1000);
    }, 100);
  },
};
</script>

<style>
.chart {
  height: 100vh;
}
</style>